import { dataRight } from './data-right'

const FooterRightTab = () => (
  <div className="hidden md:grid">
    <ul className="flex flex-wrap gap-x-2">
      {dataRight.map((item) => (
        <li key={item.title}>
          <a href={`${item.path}`} aria-label="link" rel="noopener noreferrer" target="_blank" className="inline-flex">
            <div className="flex h-footer cursor-pointer items-center justify-center">
              <div className="rounded-full p-1 hover:bg-white-1 hover:dark:bg-icon-icon">
                <item.icon className="fill1" />
              </div>
            </div>
          </a>
        </li>
      ))}
    </ul>
  </div>
)

export default FooterRightTab
