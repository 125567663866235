export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    () => {
      console.log('Async: Copying to clipboard was successful!') // eslint-disable-line
    },
    (err) => {
      console.error('Async: Could not copy text: ', err) // eslint-disable-line
    }
  )
}

export function readCookie(name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1')}=([^;]*)`)
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export function writeCookie(name, val, expires) {
  const date = new Date()
  date.setDate(date.getDate() + expires)
  document.cookie = `${name}=${val}; path=/; expires=${date.toUTCString()}`
}
