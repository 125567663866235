import { observer } from 'mobx-react'

import Modal from '@/components/Modal/Modal'
import { useAppState } from '@/stores'

import FinOperations from '@/views/Modals/Methods/FinOperations'

const FinOperationsModal = () => {
  const {
    walletAccountStore: { accountReserves },
    modalStore: { isShowFinOperationModal, finOperationsModalData }
  } = useAppState()

  if (!finOperationsModalData?.contract || !accountReserves?.[finOperationsModalData.contract]) {
    return (
      <Modal isShow={isShowFinOperationModal} isDisabledOuterClick={false}>
        <div className="card w-full max-w-[512px] p-4 md:p-6">
          <div className="mb-8 flex items-center justify-between">
            <div className="text-2xl font-semibold text-white-900 dark:text-textGray-900">Loading...</div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal isShow={isShowFinOperationModal} isDisabledOuterClick={false}>
      <FinOperations />
    </Modal>
  )
}

export default observer(FinOperationsModal)
