import { useMemo } from 'react'
import { observer } from 'mobx-react'

import { useAppState } from '@/stores'
import { incentiveDataEnum } from '@/config/constants/assets'

import Skeleton from '@/components/Common/Skeleton'
import Tooltip from '@/components/Popup/tooltip'

type APRProps = {
  contractAddress: string
  className?: string
  positionType?: string
}

const BorrowAPY: React.FunctionComponent<APRProps> = ({ contractAddress, className, positionType }) => {
  const {
    walletAccountStore: { aPYs, aPRs }
  } = useAppState()

  const { secondsPerYear } = incentiveDataEnum

  const apy = useMemo(() => {
    if (!aPYs?.[contractAddress]) return 0

    const { borrow } = aPYs[contractAddress]

    return borrow
  }, [contractAddress, aPYs])

  const rewardApy = useMemo(() => {
    if (!aPRs?.[contractAddress]) return 0

    const { borrow } = aPRs[contractAddress]
    const rewardApy = (1 + borrow / secondsPerYear) ** secondsPerYear - 1

    return rewardApy * 100
  }, [contractAddress, aPRs, secondsPerYear])

  const totalAPY = rewardApy - apy

  const contentRender = () => (
    <div className="w-[140px] p-2">
      <ul className={`${className}`}>
        <li className="flex justify-between leading-5">
          <div className="text700">Base</div>
          <div className="text900 font-medium">-{apy === null ? <Skeleton text={10} /> : apy.toFixed(2)}%</div>
        </li>
        <li className="flex justify-between leading-5">
          <div className="text700">Rewards</div>
          <div className="text900 font-medium">
            +{rewardApy === null ? <Skeleton text={10} /> : rewardApy.toFixed(2)}%
          </div>
        </li>
      </ul>
      <div className="border300 m-2 border-t"> </div>
      <div className="flex justify-between">
        <div className="text700">Total APY</div>
        <div className="text900 font-medium">{totalAPY.toFixed(totalAPY < 1000000 ? 2 : 0)}%</div>
      </div>
    </div>
  )

  return (
    <div>
      <Tooltip
        type="infoModal"
        cloudStyle={positionType === 'mobile' ? 'right-24 top-1/2' : 'top-1/2'}
        arrowStyle="hidden"
        content={contentRender()}
      >
        <div className="text900 font-semibold">{totalAPY.toFixed(totalAPY < 1000000 ? 2 : 0)} %</div>
      </Tooltip>
    </div>
  )
}

export default observer(BorrowAPY)
