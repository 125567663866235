import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useAccount, useDisconnect, useNetwork, useBalance } from 'wagmi'
import {
  TbLogout,
  TbCopy,
  TbChecks,
  TbExternalLink,
  TbUserHexagon,
  // TbInfoHexagon,
  TbStatusChange
} from 'react-icons/tb'
import CloseIcon from '@/public/svg/close.svg'

import { useAppState } from '@/stores'
import { explorerURL } from '@/config/constants/networks'
import { numberFormat, toShortAddress } from '@/utils/format'

import Modal from '@/components/Modal/Modal'
import ClientOnlyRender from '@/components/Common/ClientOnlyRender'
// import Tooltip from '@/components/Popup/tooltip'

const ProfileModal = () => {
  const {
    modalStore: { closeModal, isShowProfileModal, openMNTWrapModal },
    walletAccountStore: { getAccountInfoMantle, accountInfoMantle }
  } = useAppState()

  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()
  const { data: balance } = useBalance({ address })

  const balanceAmount = balance ? Number(balance.formatted) : 0

  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (!address) {
      closeModal()
    } else {
      getAccountInfoMantle(address)
    }
  }, [address, closeModal, getAccountInfoMantle])

  const handleCopy = () => {
    navigator.clipboard.writeText(address).catch()
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 5000)
  }

  const handleClickWrap = () => {
    closeModal()
    openMNTWrapModal()
  }

  return (
    <Modal isShow={isShowProfileModal && !!address} isDisabledOuterClick={false}>
      <ClientOnlyRender>
        <div className="text900 card flex w-full max-w-[360px] flex-col items-center justify-center p-5">
          <div className="flex w-full flex-col items-center justify-between">
            <div className="text900 flex w-full justify-end text-2xl font-semibold">
              <button
                className="flex cursor-pointer items-center hover:scale-105"
                type="button"
                aria-label="close"
                onClick={() => closeModal()}
              >
                <CloseIcon className="stroke1 inline-block" />
              </button>
            </div>

            <div className="mb-4 flex w-full flex-col justify-center gap-1">
              <div className="mb-4 flex justify-center">
                {accountInfoMantle?.nfts?.[0] ? (
                  <div className="overflow-hidden rounded-full">
                    <img src={accountInfoMantle?.nfts?.[0]} alt="Citizen" width={48} height={48} />
                  </div>
                ) : (
                  <div className="bg200 flex-0 flex rounded-full p-4">
                    <TbUserHexagon className="text-3xl" />
                  </div>
                )}
              </div>
              <div className="text900 flex items-center justify-center text-xl font-semibold">
                <span className="">{address ? toShortAddress(address, 4, 4) : null}</span>
                <a
                  href={`${explorerURL[chain?.id]}/address/${address}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="link"
                  className="text600 ml-1 block text-center text-base"
                >
                  <TbExternalLink className="flex items-center" />
                </a>
              </div>
              <div className="flex justify-center gap-1">
                <div className="flex justify-center text-gray-600">{numberFormat(balanceAmount)} CORE</div>
                <button type="button" aria-label="swap" className="flex items-center" onClick={() => handleClickWrap()}>
                  <TbStatusChange className="cursor-pointer text-base text-textGray-700" title="Swap CORE -> WCORE" />
                </button>
              </div>
              {/* <div className="flex w-full flex-1 items-center justify-center gap-1 font-semibold text-textGray-600">
                {accountInfoMantle?.miles?.total ?? '-'} Miles
                <Tooltip
                  cloudStyle="w-44 -left-20 -top-12"
                  content="Miles"
                  icon={<TbInfoHexagon />}
                  contentRender={
                    <div className="flex flex-col items-start justify-start gap-2 rounded-second text-sm text-textGray-600">
                      <div className="">Lendle: {Number(accountInfoMantle?.miles?.lendle)}</div>
                      <div className="">
                        Others: {Number(accountInfoMantle?.miles?.total) - Number(accountInfoMantle?.miles?.lendle)}
                      </div>
                    </div>
                  }
                />
              </div> */}
            </div>
            <div className="flex w-full flex-1 gap-4">
              <button
                className="bg200 flex flex-1 cursor-pointer flex-col items-center rounded-full px-4 py-2 duration-75 hover:scale-[102%]"
                type="button"
                onClick={handleCopy}
              >
                {isCopied ? <TbChecks className="text-xl" /> : <TbCopy className="text-xl" />}
                <span className="text-sm font-semibold">Copy address</span>
              </button>
              <button
                className="bg200 flex flex-1 cursor-pointer flex-col items-center rounded-full px-4 py-2 duration-75 hover:scale-[102%]"
                type="button"
                onClick={() => disconnect()}
              >
                <TbLogout className="text-xl" />
                <span className="text-sm font-semibold">Disconnect</span>
              </button>
            </div>
          </div>
        </div>
      </ClientOnlyRender>
    </Modal>
  )
}

export default observer(ProfileModal)
