import ExportIcon from '@/public/svg/export16.svg'

export type UnsuccessProps = {
  step: any
  next: any
  error?: any
}

const Unsuccess: React.FunctionComponent<UnsuccessProps> = ({ step, next }) => {
  const { txHash } = step

  return (
    <div className="box-border flex w-full flex-col justify-center border-0 pt-5">
      <div className="rounded-md border border-white-100">
        <div className="flex justify-between pt-3">
          <div className="flex flex-1 justify-center border-b border-colorError pb-3 ">
            <div className="flex items-center gap-2">
              <div className="rounded-full border-0 bg-colorError p-1 text-s font-semibold">
                <div className=" flex h-4 w-4 items-center justify-center">1</div>
              </div>
              <div className="text-xs font-semibold text-colorError">Usage as collateral</div>
            </div>
          </div>
          <div className="flex flex-1 justify-center border-b border-colorError pb-3">
            <div className="flex items-center gap-2">
              <div className="rounded-full border-0 bg-colorError p-1 text-s font-semibold">
                <div className=" flex h-4 w-4 items-center justify-center">2</div>
              </div>
              <div className="text-xs font-semibold text-colorError">Failed</div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-4 py-10 md:px-8 ">
          <div className="text-lg font-semibold text-colorError">
            Unsuccess!
            <p className="pb-6 text-sm font-medium text-white-600">{/* {error} */}</p>
          </div>
          <button
            type="button"
            onClick={() => next({ index: 0 })}
            className="text-white ml-8 box-border w-36 rounded-full border-0 bg-white-100 p-3 text-sm font-semibold"
          >
            Back
          </button>
        </div>
        <div className="border-t border-white-100 px-2 py-3 md:px-8">
          <div className="flex items-center justify-between">
            <div className="text-white text-xs font-semibold">Usage as collateral</div>
            <div className="flex items-center gap-2">
              <div className="text-white text-xs font-semibold">Confirmed</div>
              <div className="h-2 w-2 rounded-full border-0 bg-colorError" />
            </div>
            <div className="flex gap-6 md:gap-20">
              {txHash && (
                <a className="flex items-center pl-2 sm:pl-20" href={txHash} rel="noopener noreferrer" target="_blank">
                  <p className="pr-2 text-xs font-semibold">Explorer</p>
                  <ExportIcon />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Unsuccess
