import { useState, useRef } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import MenuButtonTab from '@/components/MenuButtonTab/MenuButtonTab'
import { MainMenuData } from '@/components/MainManu/data'
import ThemeSwitch from '@/components/ThemeSwitch'
// import BuyLendButton from '@/components/BuyButton/BuyLendButton'
// import BuyButton from '@/components/BuyButton'
// import CurrencyDropDown from '@/components/DropDown/CurrencyDropDown'
// import LanguageDropDown from '@/components/DropDown/LanguageDropDown'

import CloseIcon from '@/public/svg/close.svg'
import MenuIcon from '@/public/svg/icon-menu-button.svg'

const MenuButton = () => {
  const router = useRouter()

  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const modalRef = useRef<HTMLDivElement | null>(null)

  return (
    <div>
      {!isOpenMenu ? (
        <button
          className="border300 shadow-connectBtnShadows ml-2 flex h-10 w-10 items-center justify-center space-x-2 rounded-full border bg-white-1 px-2 dark:bg-white-100 md:hidden"
          type="button"
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          <MenuIcon alt="menu" className="stroke1" />
        </button>
      ) : (
        <div
          className="fixed inset-0 z-10 flex h-full w-full items-center justify-center bg-gray-700 bg-opacity-50"
          ref={modalRef}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
          aria-hidden
        >
          <div
            className={`fixed left-0 top-0 z-10 h-full w-2/3 justify-end bg-bg-bgGrey duration-300 ease-in-out dark:bg-bg-body sm:w-1/2 md:hidden lg:hidden ${
              isOpenMenu ? 'translate-x-0' : 'translate-x-0'
            }`}
          >
            <button
              type="button"
              aria-label="menu"
              className=" z-100 fixed right-5 top-5"
              onClick={() => setIsOpenMenu(!isOpenMenu)}
            >
              <CloseIcon className="stroke1" />
            </button>
            <div className="mt-5 flex justify-center border-b border-gray-200 p-6 pt-10 dark:border-white-100">
              <ul className="flex flex-col justify-center">
                {MainMenuData.map((item) => (
                  <li key={item.title}>
                    <Link href={{ pathname: item.path, query: { isClientClick: true } }} as={item.path} passHref>
                      <span className="inline-flex cursor-pointer font-medium text-white-400 hover:text-textGray-900 dark:text-textGray-400 dark:hover:text-white-900">
                        <div
                          className={`flex items-center justify-center py-2 text-xl font-semibold lg:py-4 ${
                            item.path === router?.asPath
                              ? 'font-bold text-textGray-900 dark:text-white-900'
                              : 'text-textGray-400 dark:text-white-400'
                          }`}
                        >
                          {item.title}
                        </div>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="text900 flex flex-col items-center justify-center gap-5 py-10">
              {/* <BuyButton /> */}
              {/* <CurrencyDropDown /> */}
              {/* <LanguageDropDown /> */}
              {/* <BuyLendButton isMobile /> */}
              <ThemeSwitch />
              <MenuButtonTab />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MenuButton
