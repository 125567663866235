import { useAccount } from 'wagmi'
import { ethers } from 'ethers'

import { useAppState } from '@/stores'
import configJson from '@/config/index.json'
import { defaultChainId } from '@/config/constants/networks'
import { defaultChain } from '@/utils/wagmi'

import ConnectButtonCustom from '@/components/ConnectButtonCustom'

export default function ConnectWalletButton(props) {
  const { walletAccountStore } = useAppState()

  const availableNetworkIds = Object.keys(configJson.networks)

  useAccount({
    onConnect: async (args) => {
      const { connector } = args
      if (connector != null) {
        const provider = await connector.getProvider()
        const chainId = provider.networkVersion || provider.chainId || defaultChain.id || defaultChainId
        if (provider != null && availableNetworkIds.includes(chainId.toString())) {
          const web3 = new ethers.BrowserProvider(provider)
          walletAccountStore.initWithWalletConnection(web3, connector)
        }
      }
    },
    onDisconnect: () => {
      walletAccountStore.onDisconnectWallet()
    }
  })
  return <ConnectButtonCustom {...props} />
}
