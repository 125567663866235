import React from 'react'

import { observer } from 'mobx-react'

import { useAppState } from '@/stores'

import InfoModal from './Modals/InfoModal'
import StepModal from './Modals/StepModal'
import TermsModal from './Modals/TermsModal'
import FinOperationsModal from './Modals/FinOperationsModal'
import ProfileModal from './Modals/Profile/ProfileModal'
import SwapNative from './Modals/Swap/SwapNative'
import BuyLendModal from './Modals/BuyLendModal'

const ModalsWrapper: React.FC = () => {
  const {
    modalStore: {
      isShowProfileModal,
      isShowStepModal,
      isShowTermsModal,
      isShowNativeTokenWrap,
      isShowModal,
      isShowFinOperationModal,
      isShowBuyLend
    }
  } = useAppState()

  return (
    <div className="">
      {isShowStepModal ? <StepModal /> : null}
      {isShowModal ? <InfoModal /> : null}
      {isShowTermsModal ? <TermsModal /> : null}
      {isShowFinOperationModal ? <FinOperationsModal /> : null}
      {isShowProfileModal ? <ProfileModal /> : null}
      {isShowNativeTokenWrap ? <SwapNative /> : null}
      {isShowBuyLend ? <BuyLendModal /> : null}
    </div>
  )
}

export default observer(ModalsWrapper)
