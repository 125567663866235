import SpinIcon from '@/public//svg/circle-spin.svg'

export default function OverView({ next, step, loading = false }) {
  const { symbol, closeModal, toggle } = step
  return (
    <div className="box-border flex w-full flex-col justify-center border-0 pt-5">
      <div className="rounded-md border border-white-100">
        <div className="flex justify-between pt-3">
          <div className="flex flex-1 justify-center border-b border-bg-button pb-3 ">
            <div className="flex items-center gap-2">
              <div className="rounded-full border-0 bg-bg-button p-1 text-s font-semibold">
                <div className=" flex h-4 w-4 items-center justify-center">1</div>
              </div>
              <div className="text-white text-xs font-semibold">Usage as collateral</div>
            </div>
          </div>
          <div className="flex flex-1 justify-center border-b border-white-100 pb-3">
            <div className="flex items-center gap-2">
              <div className="rounded-full border-0 bg-gray-900 p-1 text-s font-semibold">
                <div className=" flex h-4 w-4 items-center justify-center">2</div>
              </div>
              <div className="text-xs font-semibold text-white-600">Finished</div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between p-4 md:p-8">
          <div className="flex flex-col gap-4">
            <div className="text-white text-lg font-semibold">1/2 Usage as collateral</div>
            <div className="text-sm font-normal text-white-600">
              {!loading
                ? `Please submit to ${toggle ? 'use' : 'not use'} ${symbol} as collateral`
                : 'Please confirm the action'}
            </div>
          </div>
          {loading && (
            <SpinIcon className="mr-2 inline h-4 w-4 animate-spin fill-gray-300 text-gray-600 dark:fill-gray-600 dark:text-gray-200" />
          )}
          <div className="flex flex-col items-end">
            <button
              type="button"
              onClick={() => closeModal()}
              className="text-white m-1 box-border rounded-lg border-0 bg-white-100 p-3 text-sm font-semibold md:w-36"
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={loading}
              onClick={() => next({})}
              className="text-white m-1 box-border rounded-lg border-0 bg-bg-button p-3 text-sm font-semibold disabled:opacity-70 md:w-36"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
