import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { hasCookie, setCookie, getCookie } from 'cookies-next'

import { useAppState } from '@/stores'

import Sun from '@/public/svg/icon-sun.svg'
import Moon from '@/public/svg/icon-moon-1.svg'

function ThemeSwitch() {
  const {
    globalStore: { isDarkTheme, toggleTheme }
  } = useAppState()

  useEffect(() => {
    if (hasCookie('darkTheme') && getCookie('darkTheme') === 'true' && !isDarkTheme) {
      toggleTheme()
    }
  }, []) // eslint-disable-line

  const toggleButton = () => {
    const cookieThemeValue = isDarkTheme ? 'false' : 'true'
    setCookie('darkTheme', cookieThemeValue, {})
    toggleTheme()
  }

  useEffect(() => {
    if (isDarkTheme) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkTheme])

  return (
    <button
      onClick={() => toggleButton()}
      type="button"
      aria-label="change theme"
      className="border300 shadow-connectBtnShadows flex h-7 w-7 items-center justify-center rounded-full border bg-bg-white dark:bg-white-100"
    >
      <div
        className={
          !isDarkTheme ? 'flex h-full w-full items-center justify-center rounded-full px-1 hover:bg-gray-300' : 'hidden'
        }
      >
        <Moon />
      </div>
      <div
        className={
          !isDarkTheme ? 'hidden' : 'flex h-full w-full items-center justify-center rounded-full hover:bg-icon-icon'
        }
      >
        <Sun />
      </div>
    </button>
  )
}

export default observer(ThemeSwitch)
