import { observer } from 'mobx-react'
import Modal from '@/components/Modal/Modal'
import { useAppState } from '@/stores'

import UseAsCollateralStepper from '@/views/Dashboard/UseAsCollateralStepper'

const StepModal = () => {
  const {
    modalStore: { isShowStepModal }
  } = useAppState()

  return (
    <Modal isShow={isShowStepModal} isDisabledOuterClick={false}>
      <div className="card flex flex-col items-center justify-center p-5 text-white-900 dark:text-textGray-900">
        <UseAsCollateralStepper />
      </div>
    </Modal>
  )
}

export default observer(StepModal)
