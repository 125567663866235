import { observer } from 'mobx-react'

import Modal from '@/components/Modal/Modal'
import { useAppState } from '@/stores'

import { data } from './data'

const InfoModal = () => {
  const {
    modalStore: { closeModal, titleModal, isShowModal }
  } = useAppState()

  const massageData = data.find((messageData) => messageData.key === titleModal)
  const { title = '', message = '' } = massageData || {}

  return (
    <Modal isShow={isShowModal}>
      <div className="card text900 flex w-3/4 flex-col items-center justify-center p-5 md:w-1/3">
        <div className="font-semibold">
          <h1>{title}</h1>
        </div>
        <div className="my-3 px-4 text-center text-sm">
          <p>{message}</p>
        </div>
        <button onClick={() => closeModal()} type="button" className="btn-primary px-2 py-1 ">
          Ok, I got it
        </button>
      </div>
    </Modal>
  )
}

export default observer(InfoModal)
