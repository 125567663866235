import classNames from 'classnames'
import { VscQuestion } from 'react-icons/vsc'

import InfoIcon from '@/public/svg/icon_question_mark.svg'
import InfoModalIcon from '@/public/svg/info-modal-gray.svg'

export interface TooltipComponentProps {
  children?: any
  content: any
  contentRender?: React.ReactNode
  containerStyle?: string
  divStyle?: string
  imgStyle?: string
  cloudStyle?: string
  spanStyle?: string
  arrowStyle?: string
  type?: string
  icon?: React.ReactNode
}

const IconRender = (type?: string, imgStyle?: string) => {
  switch (type) {
    case 'info':
      return (
        <InfoIcon
          className={`z-0 h-4 min-h-[16px] w-4 min-w-[16px] cursor-pointer rounded-full hover:bg-white-200 ${imgStyle}`}
          width="16"
          height="16"
        />
      )
      break
    case 'infoModal':
      return (
        <InfoModalIcon
          className={`stroke1 z-0 h-4 min-h-[16px] w-4 min-w-[16px] cursor-pointer rounded-full hover:bg-white-200 ${imgStyle}`}
          width="16"
          height="16"
        />
      )
      break
    case 'noIcon':
      return null
      break
    default:
      return <VscQuestion className={classNames('hover:opacity-80', imgStyle)} />
  }
}

export default function Tooltip({
  children,
  content,
  containerStyle,
  divStyle,
  imgStyle,
  cloudStyle,
  spanStyle,
  arrowStyle,
  type,
  icon,
  contentRender
}: TooltipComponentProps) {
  return (
    <div className={`group flex gap-1 ${containerStyle}`}>
      {children && children}
      <div className={classNames('relative flex items-center', divStyle)}>
        {icon ?? IconRender(type, imgStyle)}
        <div
          className={`absolute bottom-0 z-10 mb-6 hidden flex-col items-center justify-center pb-2 group-hover:flex ${cloudStyle}`}
        >
          <div
            className={`bg900 text900 whitespace-no-wrap relative w-auto rounded-second border border-gray-300 p-2 text-center text-xs font-normal leading-4 shadow-lg dark:border-white-300 ${spanStyle}`}
          >
            {contentRender ?? content}
          </div>
          <div className={`bg900 -mt-2 h-3 w-3 rotate-45 ${arrowStyle}`} />
        </div>
      </div>
    </div>
  )
}
