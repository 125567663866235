import { useState, useMemo, useCallback, useEffect, ChangeEvent } from 'react'
import { observer } from 'mobx-react'
import { ethers } from 'ethers'
import { useAccount, useNetwork, useBalance } from 'wagmi'
import Image from 'next/legacy/image'
import _ from 'lodash'

import { useAppState } from '@/stores'
import {
  formatUnits,
  formatCalculatedHealthFactor,
  formatHealthFactor,
  hexToDecimalAmount,
  numberFormat,
  formatBIUnit,
  truncateDecimal,
  formatBIString
} from '@/utils/format'
import { getApproveData } from '@/utils/protocol'
import {
  finOperationsEnum,
  finOperationsData,
  IFinOperationsStep,
  healthFactorWarningLevel,
  gasConfig
} from '@/config/constants/finOperations'
import { AssetsIcons } from '@/config/constants/assets'
import { explorerURL } from '@/config/constants/networks'
import { AccountReserve, AccountReserveAmounts } from '@/stores/types'
import { sendEventToCampaign } from '@/utils/sendEvent/reportForXDEFI'

import CloseIcon from '@/public/svg/close.svg'
import GasIcon from '@/public/svg/gas.svg'
import SpinIcon from '@/public/svg/circle-spin.svg'
import CheckedIcon from '@/public/svg/checked.svg'
import Wallet from '@/public/svg/Wallet.svg'
import AlertIcon from '@/public/svg/alert.svg'

import TxOverview from './TxOverview'
import TxError from './TxError'
import Progress from './Progress'

const FinOperations = () => {
  const {
    walletAccountStore: {
      userAccount,
      accountReserves,
      reserves,
      prices,
      uiPoolData,
      accountReservesAmounts,
      checkAllowance,
      approve,
      deposit,
      borrow,
      repay,
      withdraw,
      updateDataAfterFinOperation,
      addERC20Token,
      getEstimateGas
    },
    modalStore: { closeModal, finOperationsModalData }
  } = useAppState()
  const { address } = useAccount()
  const walletAddress = address
  const { chain } = useNetwork()
  const { asset, contract: reserveAddress, method } = finOperationsModalData
  const { data: balance } = useBalance({ address })
  const balanceMnt = balance ? Number(balance.formatted) : 0

  const accReserve: AccountReserve = accountReserves[reserveAddress] // TODO move to use subgraph or uiPoolData
  const accDecimals = accReserve?.decimals ?? 18

  const accountReserveAmounts: AccountReserveAmounts = useMemo(
    () => accountReservesAmounts[reserveAddress],
    [accountReservesAmounts, reserveAddress]
  )
  const priceUSD = accountReserveAmounts?.price ?? prices?.[reserveAddress]?.usd ?? 1

  const reserveLiquidityT = useMemo(() => {
    const reserve = uiPoolData?.reserveData[reserveAddress]
    if (!reserve) return 0

    return formatUnits(reserve?.availableLiquidity, reserve?.decimals)
  }, [uiPoolData, reserveAddress])

  const [states, setStates] = useState({
    isLoading: false,
    isLoadingApprove: false,
    value: '',
    isApproved: false,
    isApproveRequiredChecked: false,
    isTxSuccess: false,
    error: { message: undefined },
    isLowHealthFactorAccepted: true,
    isMaxSelected: false,
    estimateGas: 0
  })

  const isMethodRequiredApprove = useMemo(
    () => finOperationsData.find((item) => item.key === method)?.approveRequired ?? true,
    [method]
  )

  const methodData = useMemo(() => finOperationsData.find((item) => item.key === method)?.modal, [method])

  const availableAmount = useMemo(() => {
    if (!accountReserveAmounts) return null

    const _decimals = accountReserveAmounts.decimals

    if (method === finOperationsEnum.borrow) {
      return formatBIString(accountReserveAmounts.balances.availableToBorrow, _decimals)
    }

    if (method === finOperationsEnum.repay) {
      return formatBIString(accountReserveAmounts.balances.availableToRepay, _decimals)
    }

    if (method === finOperationsEnum.deposit) {
      return formatBIString(accountReserveAmounts.balances.availableToSupply, _decimals)
    }

    if (method === finOperationsEnum.withdraw) {
      return formatBIString(accountReserveAmounts.balances.availableToWithdraw, _decimals)
    }

    return null
  }, [accountReserveAmounts, method])

  const supplyAPY = reserves?.[reserveAddress]?.supplied.aprPct || 0
  const borrowAPY = reserves?.[reserveAddress]?.borrowed.aprPct || 0

  const isCollateralizationUsage =
    uiPoolData?.userReserve[accReserve?.address?.toLowerCase()]?.usageAsCollateralEnabledOnUser

  const explorerLink = useMemo(() => `${explorerURL[chain?.id]}/address/${walletAddress}`, [chain?.id, walletAddress])
  const modalTitle = useMemo(() => `${methodData.title} ${asset}`, [methodData, asset])

  const progressSteps = useMemo(() => {
    const steps: IFinOperationsStep[] = []

    if (isMethodRequiredApprove && states.isApproveRequiredChecked && states.isLoadingApprove) {
      steps.push({
        title: 'Approve',
        status: 'Pending',
        explorerURL: explorerLink,
        isPending: true
      })
    }

    if (isMethodRequiredApprove && states.isApproveRequiredChecked && states.isApproved) {
      steps.push({
        title: 'Approve',
        status: 'Confirmed',
        explorerURL: explorerLink
      })
    }

    if ((!isMethodRequiredApprove || states.isApproved) && states.isLoading) {
      steps.push({
        title: modalTitle,
        status: 'Pending',
        explorerURL: explorerLink,
        isPending: true
      })
    }

    if (states.isTxSuccess) {
      steps.push({
        title: modalTitle,
        status: 'Confirmed',
        explorerURL: explorerLink
      })
    }

    return steps
  }, [states, modalTitle, isMethodRequiredApprove, explorerLink])

  const upStates = (newState: Record<string, unknown>) => {
    setStates((prevState) => ({ ...prevState, ...newState }))
  }

  const handleAllowance = useCallback(async () => {
    if (!states.value || states.value === '0' || states.isTxSuccess) return

    if (!isMethodRequiredApprove) {
      upStates({
        isApproved: true,
        isApproveRequiredChecked: true
      })

      return
    }

    try {
      upStates({ isLoading: true })

      let amount = ethers.parseUnits(states.value, accDecimals)

      if (method === finOperationsEnum.repay && states.isMaxSelected) {
        amount = accountReserveAmounts.balances.availableToRepay
      }

      if (method === finOperationsEnum.deposit && states.isMaxSelected) {
        amount = accountReserveAmounts.balances.availableToSupply
      }

      const isRequired = await checkAllowance(reserveAddress, amount, walletAddress)

      upStates({
        isApproved: !isRequired,
        isApproveRequiredChecked: true,
        isLoading: false
      })
    } catch {
      upStates({
        isApproveRequiredChecked: false,
        isLoading: false
      })
    }
  }, [
    states.value,
    states.isMaxSelected,
    states.isTxSuccess,
    isMethodRequiredApprove,
    accDecimals,
    method,
    checkAllowance,
    reserveAddress,
    walletAddress,
    accountReserveAmounts.balances.availableToRepay,
    accountReserveAmounts.balances.availableToSupply
  ])

  const _handleAllowance = useCallback(_.debounce(handleAllowance, 1500), [handleAllowance]) // eslint-disable-line

  const callEstimateGas = async () => {
    const _amount = states.value ? ethers.parseUnits(states.value, accDecimals) : '1'
    const _method = states.isApproveRequiredChecked && !states.isApproved ? 'approve' : method
    const estimateGas = await getEstimateGas(reserveAddress, _amount, walletAddress, _method)
    if (estimateGas) {
      upStates({
        estimateGas
      })
    }
  }

  useEffect(() => {
    callEstimateGas()
  }, [states.value, states.isApproved, states.isApproveRequiredChecked])

  useEffect(() => {
    if (states.value && states.value.length !== 0) {
      _handleAllowance()

      return () => _handleAllowance.cancel()
    }
  }, [states.value, _handleAllowance])

  useEffect(() => {
    upStates({
      isApproveRequiredChecked: false
    })
  }, [method])

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > Number(availableAmount)) {
      upStates({ value: availableAmount, isMaxSelected: true })
    } else if (Number(e.target.value) < 0) {
      upStates({ value: '0', isMaxSelected: false })
    } else {
      upStates({ value: truncateDecimal(e.target.value, accDecimals), isMaxSelected: false })
    }
  }

  const healthFactor = useMemo(
    () => formatHealthFactor(userAccount?.healthFactor.toString(), userAccount?.totalDebtETH.toString()),
    [userAccount]
  )

  const getNewHealthFactor = useCallback(
    (value: number) => {
      if (
        isCollateralizationUsage === false &&
        (method === finOperationsEnum.deposit || method === finOperationsEnum.withdraw)
      )
        return healthFactor
      if (value === -1) return -1

      const liquidationThreshold = Number(userAccount.currentLiquidationThreshold) / 10000
      let totalCollateral = formatBIUnit(userAccount?.totalCollateralETH, 18)
      let totalBorrowed = formatBIUnit(userAccount.totalDebtETH, 18)

      if (method === finOperationsEnum.borrow) {
        totalBorrowed += value * priceUSD
      }

      if (method === finOperationsEnum.repay) {
        totalBorrowed -= value * priceUSD
        if (totalBorrowed < 0) {
          totalBorrowed = 0
        }
      }

      if (method === finOperationsEnum.deposit) {
        totalCollateral += value * priceUSD
      }

      if (method === finOperationsEnum.withdraw) {
        totalCollateral -= value * priceUSD
        if (totalCollateral < 0) {
          totalCollateral = 0
        }
      }

      const newHealthFactor = (totalCollateral * liquidationThreshold) / totalBorrowed

      return totalBorrowed === 0 ? -1 : formatCalculatedHealthFactor(newHealthFactor, totalBorrowed)
    },
    [userAccount, method, priceUSD, healthFactor, isCollateralizationUsage]
  )

  const newHealthFactor = useMemo(() => getNewHealthFactor(Number(states.value)), [states.value, getNewHealthFactor])

  useEffect(() => {
    if (
      newHealthFactor > -1 &&
      newHealthFactor < healthFactorWarningLevel &&
      (method === finOperationsEnum.withdraw || method === finOperationsEnum.borrow)
    ) {
      upStates({ isLowHealthFactorAccepted: false })
    } else {
      upStates({ isLowHealthFactorAccepted: true })
    }
  }, [newHealthFactor, method])

  const debtData = useMemo(() => {
    if (method !== finOperationsEnum.repay) return {}

    const assetBorrowedAmountT = formatBIUnit(accReserve?.borrowing.amount, accReserve?.decimals)

    return {
      debt: assetBorrowedAmountT,
      newDebt: Number(assetBorrowedAmountT) - Number(states.value),
      debtUsd: Number(assetBorrowedAmountT) * priceUSD,
      newDebtUsd: (Number(assetBorrowedAmountT) - Number(states.value)) * priceUSD
    }
  }, [states.value, priceUSD, method, accReserve])

  const handleApprove = async () => {
    if (!states.value || states.value === '0') return

    let amount = ethers.parseUnits(states.value, accDecimals)

    if (method === finOperationsEnum.deposit && states.isMaxSelected) {
      amount = accountReserveAmounts.balances.availableToSupply
    }

    try {
      upStates({ isLoadingApprove: true })

      const maxAmount = '115792089237316195423570985008687907853269984665640564039457584007913129639935' // (2^256 - 1)
      const _amount = method === finOperationsEnum.repay ? maxAmount : amount
      const approveRes = await approve(reserveAddress, _amount)

      await approveRes.wait()

      const approvedAmount = getApproveData(approveRes)

      let states: any = {
        isApproved: true,
        isLoadingApprove: false
      }

      if (Number(approvedAmount) < Number(amount)) {
        states = {
          ...states,
          value: hexToDecimalAmount(approvedAmount, accDecimals).toString()
        }
      }

      upStates(states)
    } catch (error: any) {
      upStates({
        isApproved: false,
        isLoadingApprove: false,
        error: {
          code: error?.code,
          status: error?.reason,
          message: error?.message
        }
      })
    }
  }

  const handleSubmit = async () => {
    try {
      let res: any = {}
      const amount = ethers.parseUnits(states.value, accDecimals)

      upStates({ isLoading: true })

      const isMax = states.isMaxSelected

      try {
        if (method === finOperationsEnum.deposit) {
          res = await deposit(reserveAddress, amount, walletAddress, isMax)
          sendEventToCampaign(walletAddress)
        }

        if (method === finOperationsEnum.borrow) {
          res = await borrow(reserveAddress, amount, walletAddress, isMax)
        }

        if (method === finOperationsEnum.repay) {
          res = await repay(reserveAddress, amount, walletAddress, isMax)
        }

        if (method === finOperationsEnum.withdraw) {
          res = await withdraw(reserveAddress, amount, walletAddress, isMax)
        }
      } catch (error: any) {
        upStates({
          isLoading: false,
          isTxSuccess: false,
          error: {
            code: error?.code,
            status: error?.reason,
            message: error?.message,
            explorerURL: error?.receipt?.hash
              ? `${explorerURL[chain?.id]}/tx/${error.receipt?.hash}`
              : error?.transaction?.from
                ? `${explorerURL[chain?.id]}/address/${error.transaction.from}`
                : undefined
          }
        })
      }

      if (res?.hash) {
        await res?.wait()
        await updateDataAfterFinOperation(reserveAddress)

        upStates({ isLoading: false, isTxSuccess: true })
      }
    } catch (error: any) {
      console.error('runMethod ERROR:', error)
      upStates({
        isLoading: false,
        error: {
          code: error?.code,
          status: error?.reason,
          message: error?.message,
          explorerURL: error?.receipt?.hash
            ? `${explorerURL[chain?.id]}/tx/${error.receipt?.hash}`
            : error?.transaction?.from
              ? `${explorerURL[chain?.id]}/address/${error.transaction.from}`
              : undefined
        }
      })
    }
  }

  const handleClose = () => {
    upStates({
      isLoading: false,
      isLoadingApprove: false,
      value: '',
      isApproved: false,
      isApproveRequiredChecked: false,
      isTxSuccess: false
    })

    closeModal()
  }

  return (
    <div className="card w-full max-w-[512px] p-4 md:p-6">
      {!states.isTxSuccess && !states.error.message ? (
        <>
          <div className="mb-8 flex items-center justify-between">
            <div className="text900 text-2xl font-bold">{modalTitle}</div>
            <button
              className="flex cursor-pointer items-center"
              type="button"
              aria-label="Close"
              onClick={() => closeModal()}
            >
              <CloseIcon className="stroke1 inline-block" />
            </button>
          </div>

          <Progress totalSteps={methodData.totalSteps} steps={progressSteps} />

          <div className="flex flex-col">
            <div className="mb-8 flex flex-col gap-5">
              {method === finOperationsEnum.borrow && (
                <div className="bg100 flex flex-col gap-5 rounded-second px-3 py-4">
                  <div className="flex justify-between">
                    <div className="text600 text-sm">Variable borrow APY</div>
                    <div className="text900 text-base font-medium">{numberFormat(borrowAPY)}%</div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text600 text-sm">Available liquidity</div>
                    <div className="text900 text-base font-medium">{numberFormat(reserveLiquidityT)}</div>
                  </div>
                </div>
              )}

              <div className="flex flex-col">
                <div className="mb-[10px] flex flex-col">
                  <div className="flex">
                    <div className="text700 text-sm font-medium">Amount</div>
                  </div>
                </div>
                <div className="border300 text600 flex flex-col justify-between rounded-second border px-4 py-3 font-bold leading-normal">
                  <div className="flex w-full justify-between">
                    <div className="inline-flex flex-1 overflow-hidden pr-2">
                      <input
                        type="number"
                        className="text900 w-full overflow-hidden bg-transparent text-2xl outline-none placeholder:text-textGray-600 placeholder:dark:text-white-600 "
                        value={states.value}
                        onChange={handleChangeInput}
                        placeholder="0.00"
                      />
                    </div>
                    <div className="text900 flex items-center justify-end gap-2 text-right text-2xl font-semibold">
                      <Image
                        src={AssetsIcons[asset] || AssetsIcons.DEFAULT}
                        alt={asset}
                        width={24}
                        height={24}
                        className="h-6 w-6"
                      />
                      <span className="text900 leading-normal">{asset}</span>
                    </div>
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="text-sm font-normal ">
                      ${numberFormat(Number(states.value) * priceUSD, { maxDigits: 2 })}
                    </div>
                    <div className="flex text-sm font-normal">
                      <span className="hidden md:block">{methodData.balance}</span>
                      <span className="ml-1">{availableAmount}</span>
                      <button
                        className="ml-2 cursor-pointer font-medium text-colorPrimary hover:opacity-80"
                        type="button"
                        onClick={() => {
                          if (Number(availableAmount) === 0) return
                          upStates({
                            value: availableAmount,
                            isMaxSelected: true
                          })
                        }}
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <TxOverview
                method={method}
                isCollateralizationUsage={isCollateralizationUsage}
                supplyAPY={supplyAPY}
                healthFactor={healthFactor}
                newHealthFactor={newHealthFactor}
                debtData={debtData}
              />

              <div className="text600 flex items-center gap-1 text-sm font-medium">
                <GasIcon className="stroke1" />
                <span className="leading-none">
                  {states.estimateGas === 0 ? '-' : `$${numberFormat(states.estimateGas, { maxDigits: 2 })}`}
                </span>
                {balanceMnt < states.estimateGas && (
                  <span className="font-bold text-colorError"> Balance is not sufficient to pay for gas</span>
                )}
              </div>

              {states.value &&
                states.value !== '0' &&
                isCollateralizationUsage === true &&
                newHealthFactor > -1 &&
                newHealthFactor < healthFactorWarningLevel &&
                method === finOperationsEnum.withdraw && (
                  <>
                    <div className="mt-1 flex items-center rounded-md bg-colorErrorDark px-2 py-1 text-xs text-gray-200">
                      <div className="mr-2 px-2">
                        <AlertIcon />
                      </div>
                      <span className="">
                        {method === finOperationsEnum.withdraw &&
                          'Withdrawing this amount will reduce your health factor and increase risk of liquidation.'}
                        {method === finOperationsEnum.borrow &&
                          'Borrowing this amount will reduce your health factor and increase risk of liquidation.'}
                      </span>
                    </div>
                    <div className="flex w-full items-center justify-center">
                      <input
                        type="checkbox"
                        name="disclaimer"
                        checked={states.isLowHealthFactorAccepted}
                        id="disclaimer"
                        onChange={() => upStates({ isLowHealthFactorAccepted: !states.isLowHealthFactorAccepted })}
                        className="btn-primary mr-2 opacity-50 checked:accent-bg-primaryLight checked:opacity-100"
                      />
                      <span className="text600 text-sm">I acknowledge the risks involved.</span>
                    </div>
                  </>
                )}
            </div>

            {isMethodRequiredApprove && states.isApproveRequiredChecked && !states.isApproved && (
              <div className="mb-3 flex w-full">
                <button
                  type="submit"
                  disabled={states.isLoadingApprove}
                  className="text900 btn-primary h-[48px] w-full p-3 text-base disabled:bg-colorPrimaryLight disabled:text-colorPrimary disabled:opacity-50"
                  onClick={handleApprove}
                >
                  {states.isLoadingApprove && (
                    <SpinIcon className="mr-2 inline h-5 w-5 animate-spin fill-colorPrimaryDark text-colorPrimary" />
                  )}
                  {`Approve ${asset} to continue`}
                </button>
              </div>
            )}

            <div className="flex w-full">
              <button
                type="submit"
                disabled={
                  !states.value ||
                  Number(states.value) <= 0 ||
                  (isMethodRequiredApprove && !states.isApproved) ||
                  states.isLoading ||
                  states.isLoadingApprove ||
                  !states.isLowHealthFactorAccepted
                }
                className="btn-primary h-[48px] w-full"
                onClick={handleSubmit}
              >
                {states.isLoading && (
                  <SpinIcon className="mr-2 inline h-5 w-5 animate-spin fill-colorPrimaryDark text-colorPrimary" />
                )}
                {!states.value || Number(states.value) === 0
                  ? 'Enter an amount'
                  : states.isLoading
                    ? `${methodData.processing} ${asset}`
                    : `${methodData.title} ${asset}`}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mb-16 flex items-center justify-end">
            <button
              className="flex cursor-pointer items-center"
              type="button"
              aria-label="Close"
              onClick={() => closeModal()}
            >
              <CloseIcon className="stroke1 inline-block" />
            </button>
          </div>
          {!states.error.message ? (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col items-center gap-3">
                <div className="flex">
                  <CheckedIcon />
                </div>
                <div className="text900 flex text-2xl font-semibold leading-8">All done!</div>
                <div className="text600 flex text-sm">
                  {methodData.success} {states.value} {asset}
                </div>
              </div>
              <Progress totalSteps={methodData.totalSteps} steps={progressSteps} />
              <div className="bg100 hidden flex-col items-center gap-3 rounded-second p-4 md:flex">
                <div className="flex items-center gap-2">
                  <Image
                    src={AssetsIcons[asset] || AssetsIcons.DEFAULT}
                    alt={asset}
                    width={32}
                    height={32}
                    className="h-6 w-6"
                  />
                  {/* <span className="text-white-1">{asset}</span> */}
                </div>
                <div className="text600 flex text-sm">Add token to wallet to track your balance.</div>
                <button
                  type="button"
                  className="btn-secondary-sm flex gap-2 px-4 py-[10px]"
                  onClick={() => addERC20Token(accReserve.address, asset, accReserve.decimals, chain.id)}
                >
                  <Wallet className="stroke1" />
                  <span>Add to wallet</span>
                </button>
              </div>
              <div className="flex">
                <button type="button" className="btn-primary h-[48px] w-full" onClick={handleClose}>
                  Ok, close
                </button>
              </div>
            </div>
          ) : (
            <TxError errorData={states.error} onClose={() => upStates({ error: { message: undefined } })} />
          )}
        </>
      )}
    </div>
  )
}
export default observer(FinOperations)
