import { observer } from 'mobx-react'
import Modal from '@/components/Modal/Modal'
import { useAppState } from '@/stores'
import { writeCookie } from '@/utils/helpers'

const TermsModal = () => {
  const {
    modalStore: { closeModal, isShowTermsModal, termsAction }
  } = useAppState()

  const handleClickAccept = () => {
    writeCookie('terms', 'accepted', 365)
    if (termsAction) {
      termsAction()
    }
    closeModal()
  }

  return (
    <Modal isShow={isShowTermsModal}>
      <div className="card flex flex-col items-center justify-center sm:w-2/3 xlg:w-1/2">
        <div className="text900 m-4 text-base md:m-8">
          <div className="text-center font-semibold">
            <h1>Terms & Conditions</h1>
          </div>
          <div className="my-8 text-sm md:text-base">
            <p className="my-3">
              {/* By proceeding to the platform You accept our Terms of service, Privacy policy and Disclaimer. */}
              By proceeding to the platform You accept a condition to accessing the services or dorianfi.com you
              acknowledge that:
            </p>
            <ul className="my-3 list-disc px-4">
              <li className="my-2">
                You declare that You are not a US person (as defined in the Securities Act of 1933, as amended), or a
                citizen or resident of Canada, or a citizen or resident of Japan
              </li>
              <li className="my-2">
                You do not and will not use VPN software or any other privacy or anonymization tool to circumvent any
                restrictions that apply to the service and the use dorianfi.com
              </li>
              <li className="my-2">
                If you are entering into these terms as an individual then you are of legal age (as applicable in your
                jurisdiction in which you reside)
              </li>
              <li className="my-2">
                Your access to the services and dorianfi.com does not violate any foreign rule, law, regulation or
                directive
              </li>
            </ul>
          </div>
          <div className="flex space-x-4">
            <button onClick={() => closeModal()} type="button" className="btn-secondary px-8">
              Decline
            </button>
            <button onClick={handleClickAccept} type="button" className="btn-primary px-8">
              Accept
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(TermsModal)
