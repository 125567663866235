import { useState } from 'react'
import { observer } from 'mobx-react'
import { useAccount, usePrepareContractWrite, useContractWrite, useBalance } from 'wagmi'
import { parseUnits, parseEther } from 'viem'
import { TbArrowNarrowRight, TbSwitchVertical } from 'react-icons/tb'

import wrapABI from '@/config/abi/MNTWrap.json'
import { useAppState } from '@/stores'

import Modal from '@/components/Modal/Modal'

import CloseIcon from '@/public/svg/close.svg'
import { numberFormat } from '@/utils/format'

const SwapNative = () => {
  const { address } = useAccount()
  const [amount, setAmount] = useState('')
  const [isUnWrap, setIsUnWrap] = useState(false)

  const {
    modalStore: { closeModal, isShowNativeTokenWrap }
  } = useAppState()

  const saleTokenDecimals = 18

  const base = isUnWrap ? 'WCORE' : 'CORE'
  const quote = isUnWrap ? 'CORE' : 'WCORE'

  const balance = useBalance({
    address: address,
    watch: true
  })

  const tokenBalance = useBalance({
    address: address,
    watch: true,
    token: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f'
  })

  const isValidInput = (input: string) => {
    if (input !== '' && !Number.isNaN(+input) && parseFloat(input) !== 0) {
      try {
        parseEther(input as `${number}`)
      } catch (e) {
        return false
      }
      return true
    }
    return false
  }

  const configParams = {
    address: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    abi: wrapABI.abi,
    functionName: isUnWrap ? 'withdraw' : 'deposit'
    // maxFeePerGas: parseGwei('0.05'),
    // maxPriorityFeePerGas: parseGwei('0.01')
  } as any

  if (isUnWrap) {
    configParams.args = [isValidInput(amount) ? parseUnits(amount as `${number}`, saleTokenDecimals!) : '0x00']
  } else {
    configParams.value = isValidInput(amount) ? parseUnits(amount as `${number}`, saleTokenDecimals!) : '0x00'
  }

  const { config } = usePrepareContractWrite(configParams)

  const { write, status, isError } = useContractWrite(config)

  const handleClick = async () => {
    write?.()
  }

  return (
    <Modal isShow={isShowNativeTokenWrap && !!address} isDisabledOuterClick={false}>
      <div className="card w-full max-w-[512px] p-4 md:p-6">
        <div className="mb-8 flex items-center justify-between">
          <div className="text900 flex items-center text-2xl font-semibold">
            Swap {base}
            <div className="">
              <TbArrowNarrowRight />
            </div>{' '}
            {quote}
          </div>
          <button
            className="flex cursor-pointer items-center"
            type="button"
            aria-label="Close"
            onClick={() => closeModal()}
          >
            <CloseIcon className="stroke1 inline-block" />
          </button>
        </div>

        <div className="">
          <div className="mb-2 flex items-center justify-between">
            <div className="text900 text-xl font-semibold">{base}</div>
            <div className="text600 flex text-sm">
              Balance{' '}
              <div className="ml-2">
                {isUnWrap
                  ? numberFormat(tokenBalance?.data?.formatted, { maxDigits: 6 })
                  : numberFormat(balance?.data?.formatted, { maxDigits: 6 })}
              </div>
            </div>
          </div>
          <div className="">
            <input
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className={`text900 w-full rounded-full border-none bg-transparent px-4 py-3 text-left text-xl outline outline-1 outline-gray-300 dark:outline-white-300 ${
                !isValidInput(amount) && amount !== ''
                  ? 'text-error focus:outline-error focus-visible:outline-error'
                  : 'focus:outline-secondary focus-visible:outline-secondary'
              }`}
              placeholder={`0.00 ${base}`}
            />
          </div>
        </div>
        <div className="text900 mb-1 mt-4 flex w-full cursor-pointer justify-center text-2xl">
          <TbSwitchVertical onClick={() => setIsUnWrap((prev) => !prev)} className="text600 h-6" />
        </div>
        <div className="mb-6 flex flex-col">
          <div className="mb-2 flex items-center justify-between">
            <div className="text900 text-xl font-semibold">{quote}</div>
            <div className="text600 flex text-sm">
              Balance{' '}
              <div className="ml-2">
                {isUnWrap
                  ? numberFormat(balance?.data?.formatted, { maxDigits: 6 })
                  : numberFormat(tokenBalance?.data?.formatted, { maxDigits: 6 })}
              </div>
            </div>
          </div>
          <div className="text900 w-full rounded-full border-none bg-transparent px-4 py-3 text-left text-xl outline outline-1 outline-gray-300 dark:outline-white-300">
            <div className="text600">{amount || `0.00 ${quote}`}</div>
          </div>
        </div>
        {isError && status !== 'loading' && <div className="mb-4 text-colorError">Something went wrong.</div>}
        <div className="flex">
          <button
            type="button"
            aria-label="submit"
            disabled={!amount || status === 'loading'}
            onClick={handleClick}
            className="btn-primary"
          >
            {status === 'loading' ? 'Loading...' : 'Swap'}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default observer(SwapNative)
