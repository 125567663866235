import React, { useCallback, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { useAppState } from '@/stores'

export interface ModalProps {
  // isShowModal: boolean
  // children: React.ReactNode
  isShow?: boolean
  isDisabledOuterClick?: boolean
  onClose?: () => void
  useHide?: boolean
}

const Modal: React.FC<ModalProps> = ({ children, isDisabledOuterClick = true, isShow, useHide, onClose }) => {
  const { modalStore } = useAppState()
  const { isShowModal, closeModal } = modalStore

  const modalRef = useRef<HTMLDivElement | null>(null)

  const handleClose = (e: any) => {
    if (modalRef.current === e.target) {
      closeModal()

      if (onClose && typeof onClose === 'function') {
        onClose()
      }
    }
  }
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && isShowModal) {
        closeModal()
      }
    },
    [closeModal, isShowModal]
  )
  const handleClickModal = (e: any) => {
    if (isDisabledOuterClick) return
    handleClose(e)
  }
  useEffect(() => {
    document.addEventListener('keydown', keyPress)
    return () => document.removeEventListener('keydown', keyPress)
  }, [keyPress])

  return (
    <>
      {isShow && !useHide && (
        <div
          className="fixed inset-0 z-10 flex h-full w-full items-center justify-center bg-gray-700 bg-opacity-50 px-2 md:px-0"
          ref={modalRef}
          onClick={(e: any) => handleClickModal(e)}
          aria-hidden
        >
          {children}
        </div>
      )}
      {useHide && (
        <div
          className={classNames(
            'fixed inset-0 z-10 h-full w-full items-center justify-center bg-gray-700 bg-opacity-50 px-2 md:px-0',
            isShow ? 'flex' : 'hidden'
          )}
          ref={modalRef}
          onClick={(e: any) => handleClickModal(e)}
          aria-hidden
        >
          {children}
        </div>
      )}
    </>
  )
}

export default observer(Modal)
