import React from 'react'

import Modals from '@/components/Modal/ModalsWrapper'
import CookieConsent from '@/components/Popup/CookieConsent'

import Header from '../Header'
import Footer from '../Footer'

interface ILayout {
  children: React.ReactNode
}

const Layout: React.FC<ILayout> = ({ children }) => (
  <div className="relative h-full w-full bg-bg-bgGrey pt-header dark:bg-bg-body">
    <Header />
    <div className="text900 mt-17 relative h-full w-full items-center overflow-x-hidden md:mb-14">
      <div className="page-container pb-16">{children}</div>
    </div>
    <Modals />
    <Footer />
    <CookieConsent />
  </div>
)

export default Layout
