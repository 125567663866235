import React, { useEffect } from 'react'

export const useOutsideClick = (ref: React.RefObject<HTMLDivElement | null>, callback: () => void): void => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}
