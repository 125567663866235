import Image from 'next/legacy/image'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAppState } from '@/stores'
import { readCookie } from '@/utils/helpers'
import ChevronDown from '@/public/svg/chevronDown.svg'
import AvatarConnect from '@/public/image/AvatarConnect.png'
import Wallet from '@/public/svg/Wallet.svg'
import MenuButton from '../MenuButton/MenuButton'

export default function ConnectButtonCustom({ noIcon }) {
  const {
    modalStore: { openTermsModal, openProfileModal }
  } = useAppState()

  const handleClick = (action: () => void) => {
    if (readCookie('terms') !== 'accepted') {
      openTermsModal(action)
      return
    }
    action()
  }

  return (
    <ConnectButton.Custom>
      {({ account, chain, openChainModal, openConnectModal, mounted }) => (
        <div
          {...(!mounted && {
            'aria-hidden': true,
            style: {
              opacity: 0,
              pointerEvents: 'none',
              userSelect: 'none'
            }
          })}
        >
          {(() => {
            if (!mounted || !account || !chain) {
              return (
                <button
                  onClick={() => handleClick(openConnectModal)}
                  type="button"
                  className="mx-auto flex h-10 items-center justify-center space-x-2 rounded-full bg-bg-button px-2 text-sm font-medium leading-[1rem] text-white-1 shadow-sm sm:px-4"
                >
                  <span>Connect wallet</span>
                  {!noIcon && <Wallet className="hidden stroke-white-1 sm:block" />}
                </button>
              )
            }

            if (chain.unsupported) {
              return (
                <button
                  onClick={() => handleClick(openChainModal)}
                  type="button"
                  className="text-connectBtnShadows rounded-full bg-colorWarning px-2 py-2 text-sm font-semibold text-white-1 sm:px-6"
                >
                  Wrong network
                </button>
              )
            }

            return (
              <div className="flex h-10 w-full items-center rounded-full focus:outline-none focus:ring-2">
                <div className="border300 flex items-center rounded-full border bg-white-1 px-2 dark:bg-white-100">
                  <button
                    // onClick={() => handleClick(openAccountModal)}
                    onClick={() => handleClick(openProfileModal)}
                    type="button"
                    className="text700 shadow-connectBtnShadows flex h-10 items-center justify-center space-x-2 px-2 text-sm font-medium"
                  >
                    <div className="hidden sm:flex">
                      {chain.iconUrl ? (
                        <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} style={{ width: 20, height: 20 }} />
                      ) : (
                        <Image src={AvatarConnect} alt="AvatarConnect" />
                      )}
                    </div>
                    <span>
                      {account.displayBalance
                        ? `${account.address.slice(0, 4)}...${account.address.slice(
                            account.address.length - 4,
                            account.address.length
                          )}`
                        : ''}
                    </span>
                  </button>
                  <button
                    onClick={() => handleClick(openChainModal)}
                    type="button"
                    aria-label="Settings"
                    className="text-700 shadow-connectBtnShadows hidden h-10 w-10 items-center justify-center space-x-2 px-2 text-sm font-medium md:flex"
                  >
                    <ChevronDown className="stroke-textGray-700 dark:stroke-white-1" />
                  </button>
                </div>
                <MenuButton />
              </div>
            )
          })()}
        </div>
      )}
    </ConnectButton.Custom>
  )
}
