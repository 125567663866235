import IconDiscord from '@/public/svg/discord-light.svg'
import IconGit from '@/public/svg/git-light.svg'
import IconTweeter from '@/public/svg/tweeter-light.svg'
import IconMedium from '@/public/svg/medium-light.svg'

export const data = [
  // {
  //   icon: IconMedium,
  //   title: 'Medium',
  //   path: 'https://dorianfi.medium.com/'
  // },
  {
    icon: IconTweeter,
    title: 'Tweeter',
    path: 'https://x.com/0xDorianFi'
  },
  {
    icon: IconDiscord,
    title: 'Discord',
    path: 'https://discord.gg/rkA2h7RNHc'
  },
  {
    icon: IconGit,
    title: 'GitHub',
    path: 'https://github.com/DorianFi'
  }
]
