import AlertIcon from '@/public/svg/alert.svg'
import ExportIcon from '@/public/svg/export16.svg'

function TxError({ errorData, onClose }) {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col items-center gap-3">
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-colorWarningBase">
          <AlertIcon />
        </div>
        <div className="text900 flex text-2xl font-semibold leading-8">Something went wrong.</div>
        <div className="text600 flex text-sm">{errorData.status}</div>
        <div className="text600 w-full overflow-x-scroll text-sm">{errorData.message}</div>
        {errorData?.explorerURL ? (
          <a href={errorData.explorerURL} rel="noopener noreferrer" target="_blank" className="flex items-center gap-2">
            <div className="text900 text-xs font-semibold">Explorer</div>
            <ExportIcon className="stroke1" />
          </a>
        ) : null}
      </div>

      <div className="flex">
        <button type="button" className="btn-primary h-[48px] w-full" onClick={() => onClose()}>
          Try again
        </button>
      </div>
    </div>
  )
}
export default TxError
