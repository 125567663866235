import { useRouter } from 'next/router'

import Logo from '@/public/svg/Logo.svg'
import HeadersConnectWalletButton from '../ConnectWalletButton'
import MainMenu from '../MainManu/MainMenu'
import BuyLendButton from '../BuyButton/BuyLendButton'
import PartnerAppsMenu from '../PartnerAppsMenu/PartnerAppsMenu'

const Header = () => {
  const router = useRouter()

  return (
    <header className="fixed top-0 z-10 flex h-header w-full items-center bg-bg-bgGrey dark:bg-bg-body">
      <div className="page-container h-full w-full">
        <div className="flex h-full flex-1 items-center justify-between">
          <div className="flex items-center">
            <button type="button" aria-label="logo" onClick={() => router.push('/')}>
              <Logo />
            </button>
            <MainMenu />
          </div>

          <div className="flex items-center">
            {/* <PartnerAppsMenu /> */}
            {/* <BuyLendButton /> */}
            <HeadersConnectWalletButton />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
