import { ethers } from 'ethers'
import configJson from '@/config/index.json'

import lendingPoolAddressesProviderJson from '@/config/abi/LendingPoolAddressesProvider.json'
import aaveProtocolDataProviderJson from '@/config/abi/AaveProtocolDataProvider.json'
import lendingPoolJson from '@/config/abi/LendingPool.json'
import { loadAccountReserveData } from './reserve'

export const networkConfig = (chainId: number): Record<string, string> => configJson.networks[chainId]

export const getAddressProvider = (provider, chainId) => {
  const config = networkConfig(chainId)
  if (!config) {
    throw new Error(`Network config not found. [ChainId: ${chainId}]`)
  }

  return new ethers.Contract(config.LendingPoolAddressesProvider, lendingPoolAddressesProviderJson.abi, provider)
}

export const getDataProvider = (provider, chainId) => {
  const config = networkConfig(chainId)
  if (!config) {
    throw new Error(`Network config not found. [ChainId: ${chainId}]`)
  }

  return new ethers.Contract(config.AaveProtocolDataProvider, aaveProtocolDataProviderJson.abi, provider)
}

export const getLendingPoolAddress = (provider, chainId): Promise<string> => {
  const addressProviderContract = getAddressProvider(provider, chainId)
  return addressProviderContract.getLendingPool()
}

export const getLendingPool = async (provider, chainId) => {
  const lpAddress = await getLendingPoolAddress(provider, chainId)
  return new ethers.Contract(lpAddress, lendingPoolJson.abi, provider)
}

export const getAccountReserves = async (account, provider, chainId): Promise<any> => {
  const lpContract = await getLendingPool(provider, chainId)
  const reservesList = await lpContract.getReservesList()
  return Promise.all(reservesList.map((reserve) => loadAccountReserveData(lpContract, reserve, account)))
}

export const getApproveData = (approveRes) => {
  const abiCoder = new ethers.AbiCoder()
  const data = abiCoder.decode(['address', 'uint256'], ethers.dataSlice(approveRes.data, 4))

  return data?.[1]
}
