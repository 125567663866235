import { useNetwork } from 'wagmi'
import { useState } from 'react'
import { observer } from 'mobx-react'

import { useAppState } from '@/stores'
import { explorerURL } from '@/config/constants/networks'
import ArrowLeftIcon from '@/public/svg/arrow-left-2.svg'

import OverView from './OverView'
import OverViewPending from './OverViewPending'
import Congrats from './Congrats'
import Unsuccess from './Unsuccess'

const UseAsCollateralStepper = () => {
  const {
    walletAccountStore: { setAsCollateral, lpUtils },
    modalStore: { stepModalDate, closeModal }
  } = useAppState()
  const { setToggle, assetAddress, asset, toggle } = stepModalDate
  const { chain } = useNetwork()

  const initStep = {
    index: 0,
    txHash: '',
    symbol: asset,
    toggle: toggle,
    title: asset,
    closeModal
  }
  const [error, setError] = useState(null)

  const [step, setStep] = useState(initStep)
  const upState = (payload) => {
    setStep((currentStep) => ({
      ...currentStep,
      index: currentStep.index + 1,
      ...payload
    }))
  }
  const upStep = async (payload) => {
    const nextStep = step.index + 1
    upState(payload)

    if (nextStep === 1) {
      try {
        setError(null)
        const res = await setAsCollateral(assetAddress, !toggle)
        upState({
          txHash: `${explorerURL[chain?.id]}/tx/${res.hash}`,
          index: 2
        })
        try {
          const response = await res.wait()
          upState({
            txHash: `${explorerURL[chain?.id]}/tx/${response.transactionHash}`,
            index: 3
          })
          setToggle(!toggle)
        } catch (err) {
          setError(err)
          upState({ index: 4 })
        }
      } catch (err) {
        setError(err)
        upState({ index: 4 })
      }
    }
  }

  return (
    <div>
      <div className="rounded-lg border-gray-100 dark:border-white-100">
        <div className="rounded-lg border border-gray-100 p-3 dark:border-white-100 md:p-8">
          <button className="mb-8 flex cursor-pointer items-center gap-6" type="button" onClick={() => closeModal()}>
            <ArrowLeftIcon className="inline-block" />
            <div className="text900 mr-8 inline-block text-lg font-semibold">Back</div>
          </button>

          <div className="flex w-full flex-col items-center justify-center">
            <div className="mx-6 flex flex-col items-center text-2xl">
              <p className="text900 pb-4 text-2xl font-semibold">
                {step.index === 3 ? 'Congrats!' : `${toggle ? 'Use' : 'Do not use'} ${asset} as collateral`}
              </p>
              <p className="text600 pb-6 text-sm font-medium">
                {step.index !== 3 &&
                  step.index !== 4 &&
                  'These are your transaction details. Make sure to check if this is correct before submitting'}
                {step.index === 3 && 'You action has been successfully executed'}
                {step.index === 4 && 'Your action failed'}
              </p>
            </div>

            {step.index === 0 ? (
              <OverView next={upStep} step={step} />
            ) : step.index === 1 ? (
              <OverView next={upStep} step={step} loading />
            ) : step.index === 2 ? (
              <OverViewPending step={step} />
            ) : step.index === 3 ? (
              <Congrats step={step} closeModal={closeModal} />
            ) : (
              <Unsuccess step={step} next={upStep} error={error} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default observer(UseAsCollateralStepper)
