const xdefiApiUrl = 'https://campaign-ts.xdefi.services/api/campaigns/lendle_01/events'
const partnerName = 'lendle'
const partnerKey = '81FC99739C85F'

export async function sendEventToCampaign(address: string): Promise<any> {
  try {
    const response = await fetch(xdefiApiUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        chain: 'ethereum',
        address,
        partnerName,
        partnerKey,
        metadata: 'string'
      })
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error('Error sending event:', error)
    throw error
  }
}
