import { useEffect, useState } from 'react'
import { hasCookie, setCookie } from 'cookies-next'

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(true)

  useEffect(() => {
    setShowConsent(hasCookie('userAcceptedAnalytics'))
  }, [])

  const acceptCookie = () => {
    setShowConsent(true)
    setCookie('userAcceptedAnalytics', 'true', {})
  }

  const rejectCookie = () => {
    setShowConsent(true)
    setCookie('userAcceptedAnalytics', 'false', {})
  }

  if (showConsent) {
    return null
  }

  return (
    <div className="fixed bottom-2 right-0 z-50 px-2 md:bottom-5 md:right-5 md:px-0">
      <div className="border300 card flex max-w-[420px] flex-col items-center justify-between border px-4 py-6 text-sm">
        <span className="text900 mb-4">
          We may employ on-the-spot tracking techniques during your browsing session to collect data on your
          interactions, preferences, and behavior. This data helps us personalize your experience and improve our
          services.
        </span>
        <div className="flex w-full gap-4">
          <button type="submit" className="btn-primary flex-1 px-8 py-2" onClick={() => acceptCookie()}>
            Accept
          </button>
          <button type="submit" className="btn-secondary flex-1 px-8 py-2" onClick={() => rejectCookie()}>
            Opt-out
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookieConsent
