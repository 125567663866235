import { numberFormat } from '@/utils/format'

export type HealthFactorNumberProps = {
  value: number
}

const HealthFactorNumber: React.FunctionComponent<HealthFactorNumberProps> = ({ value }) => {
  if (value === -1) return <>-</>

  let healthFactorColor = ''
  if (value >= 3) {
    healthFactorColor = 'text-healthFactor-main'
  } else if (value < 1.1) {
    healthFactorColor = 'text-healthFactor-error'
  } else {
    healthFactorColor = 'text-healthFactor-warning'
  }

  return <span className={healthFactorColor}>{numberFormat(value)}</span>
}

export default HealthFactorNumber
