import { useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'

import { useOutsideClick } from '@/hooks/useOutsideClick'

import { MainMenuData } from './data'

const MainMenu = () => {
  const router = useRouter()
  const [isShowMore, setIsShowMore] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setIsShowMore(false))

  const mainMenuItems = MainMenuData.filter((item) => !item.more && !item.noTablet)
  const noTablenMenuItems = MainMenuData.filter((item) => item.noTablet)
  const moreMenuItems = MainMenuData.filter((item) => item.more)

  const renderMainLink = (item) => (
    <Link
      href={{ pathname: item.path, query: { isClientClick: true } }}
      as={item.path}
      passHref
      className="flex items-center px-2 xlg:px-4"
    >
      {item.icon && (
        <item.icon
          className={classNames(
            'mr-2',
            item.path === router?.asPath || item?.children?.find((child) => router.asPath.includes(child))
              ? 'stroke-textGray-900 group-hover:stroke-textGray-900 dark:stroke-white-1 group-hover:dark:stroke-white-1'
              : ''
          )}
        />
      )}
      <span className="group inline-flex text-gray-400 hover:text-textGray-900">
        <div
          className={classNames(
            'full box-border flex h-header cursor-pointer items-center justify-center text-sm font-semibold hover:text-textGray-900 hover:dark:text-white-1 md:text-base',
            item.path === router?.asPath || item?.children?.find((child) => router.asPath.includes(child))
              ? 'text900 border-colorPrimary font-bold'
              : 'border-colorTransparent dark:text-gray-400'
          )}
        >
          <span> {item.title}</span>
        </div>
      </span>
    </Link>
  )

  const renderMoreLink = (item) => (
    <Link
      href={{ pathname: item.path, query: { isClientClick: true } }}
      as={item.path}
      passHref
      className="flex items-center justify-start"
    >
      {item.icon && (
        <item.icon
          className={classNames(
            'w-[28px]  hover:dark:text-white-900',
            item.path === router?.asPath || item?.children?.find((child) => router.asPath.includes(child))
              ? ' stroke-textGray-900 dark:stroke-white-1'
              : ''
          )}
        />
      )}
      <span className="group inline-flex text-gray-400 hover:text-white-1 hover:dark:text-textGray-900">
        <div
          className={classNames(
            'full box-border flex cursor-pointer items-center justify-center text-base font-semibold hover:text-textGray-900 hover:dark:text-white-1',
            item.path === router?.asPath || item?.children?.find((child) => router.asPath.includes(child))
              ? 'text900 border-colorPrimary font-bold'
              : 'border-colorTransparent dark:text-gray-400'
          )}
        >
          <span> {item.title}</span>
        </div>
      </span>
    </Link>
  )

  return (
    <div className="hidden md:flex">
      <ul className="ml-3 flex flex-nowrap md:px-2 xlg:ml-12">
        {mainMenuItems.map((item) => (
          <li key={item.title} className="group">
            {renderMainLink(item)}
          </li>
        ))}
        {noTablenMenuItems.length > 0 &&
          noTablenMenuItems.map((item) => (
            <li key={item.title} className="group hidden lg:block">
              {renderMainLink(item)}
            </li>
          ))}
        <div
          className={classNames(
            'relative box-border flex items-center',
            moreMenuItems?.find((child) => router.asPath.includes(child?.title.toLowerCase()))
              ? 'text900 border-colorPrimary font-bold'
              : 'border-colorTransparent dark:text-gray-400'
          )}
        >
          <div className="relative flex flex-col text-gray-400" ref={wrapperRef}>
            {/* <button
              type="button"
              className={classNames(
                'flex cursor-pointer items-center px-4 text-base font-semibold hover:text-textGray-900 hover:dark:text-white-1',
                isShowMore || moreMenuItems?.find((child) => router.asPath.includes(child?.title.toLowerCase()))
                  ? 'text-textGray-900 dark:text-white-1'
                  : ''
              )}
              onClick={() => setIsShowMore((state) => !state)}
            >
              <span>More</span>
              {isShowMore ? <TbChevronUp className="mt-[2px]" /> : <TbChevronDown className="mt-[2px]" />}
            </button> */}
            {isShowMore && (
              <div className="absolute left-0 top-full z-20 mt-2 flex min-w-[160px] flex-col rounded-base bg-bg-white px-3 py-2 shadow-lg dark:bg-card-c1">
                {noTablenMenuItems.length > 0 &&
                  noTablenMenuItems.map((item) => (
                    <li key={item.title} className="flex min-h-[40px] lg:hidden">
                      {renderMoreLink(item)}
                    </li>
                  ))}
                {moreMenuItems.length > 0 &&
                  moreMenuItems.map((item) => (
                    <li key={item.title} className="flex min-h-[40px]">
                      {renderMoreLink(item)}
                    </li>
                  ))}
              </div>
            )}
          </div>
        </div>
      </ul>
    </div>
  )
}

export default MainMenu
