import SpinIcon from '@/public//svg/circle-spin.svg'
import ExportIcon from '@/public/svg/export16.svg'

export default function OverViewPending({ step }) {
  const { txHash } = step

  return (
    <div className="box-border flex w-full flex-col justify-center border-0 pt-5">
      <div className="rounded-md border border-white-100">
        <div className="flex justify-between pt-3">
          <div className="flex flex-1 justify-center border-b border-bg-button pb-3 ">
            <div className="flex items-center gap-2">
              <div className="rounded-full border-0 bg-bg-button p-1 text-s font-semibold">
                <div className=" flex h-4 w-4 items-center justify-center">1</div>
              </div>
              <div className="text-white text-xs font-semibold">Usage as collateral</div>
            </div>
          </div>
          <div className="flex flex-1 justify-center border-b border-white-100 pb-3">
            <div className="flex items-center gap-2">
              <div className="rounded-full border-0 bg-gray-900 p-1 text-s font-semibold">
                <div className=" flex h-4 w-4 items-center justify-center">2</div>
              </div>
              <div className="text-xs font-semibold text-white-600">Pending</div>
            </div>
          </div>
        </div>
        <div className="px-4 py-10 md:px-8 ">
          <div className="text-lg font-semibold text-colorWarning">Transaction(s) Pending</div>
        </div>
        <div className="border-t border-white-100 px-2 py-3 md:px-8">
          <div className="flex items-center justify-between">
            <div className="text-white text-xs font-semibold">Usage as collateral</div>
            <div className="flex items-center gap-2">
              <div className="text-white text-xs font-semibold">Pending</div>
              <SpinIcon className="mr-2 inline h-4 w-4 animate-spin fill-gray-300 text-gray-600 dark:fill-gray-600 dark:text-gray-200" />
            </div>
            <div className="">
              <a className="flex items-center pl-2" href={txHash} rel="noopener noreferrer" target="_blank">
                <p className="pr-2 text-xs font-semibold">Explorer</p>
                <ExportIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
