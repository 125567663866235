import Link from 'next/link'

// import { useTotalValueLocked } from '@/utils/dataHooks'
// import { numberFormat } from '@/utils/format'

import FooterRightTab from './FooterRightTab'
import { linkData } from './data'
// import FooterBlocks from './FooterBlocks'
// import ClientOnlyRender from '../Common/ClientOnlyRender'
import ThemeSwitch from '../ThemeSwitch'

function Footer() {
  // const totalValueLocked = useTotalValueLocked()

  return (
    <footer className="border300 fixed bottom-0 z-10 box-border w-full border-t bg-bg-bgGrey dark:bg-bg-footer">
      <div className="page-container">
        <div className="flex flex-1 items-center justify-between">
          <div className="text600 hidden h-footer text-sm font-medium md:grid">
            <ul className="flex flex-wrap items-center gap-x-4">
              {/* <FooterBlocks />
              <ClientOnlyRender>
                <div className="group relative items-center justify-center rounded-full bg-white-100 px-2 py-1 dark:bg-bg-primaryLight dark:text-textGray-900 sm:flex">
                  <button type="button">Markets: {numberFormat(totalValueLocked, { isSymbolHide: true })} USD</button>
                </div>
              </ClientOnlyRender> */}
              <li>
                <ThemeSwitch />
              </li>
              {linkData.map((item) => (
                <li key={item.title}>
                  <Link href={`${item.path}`} passHref>
                    <div className="inline-flex">
                      <div className="flex h-footer cursor-pointer items-center justify-center text-sm font-medium hover:font-bold hover:text-textGray-900 dark:hover:text-white-1">
                        <span> {item.title}</span>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <FooterRightTab />
        </div>
      </div>
    </footer>
  )
}

export default Footer
