export const linkData = [
  // {
  //   title: 'Terms',
  //   path: ''
  // },
  // {
  //   title: 'Privacy',
  //   path: ''
  // },
  {
    title: 'Doc',
    path: 'https://docs.dorianfi.com/'
  }
]
