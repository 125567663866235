import classNames from 'classnames'

export type SkeletonProps = {
  className?: string
  classNameWrapper?: string
  text?: number
}

const Skeleton: React.FunctionComponent<SkeletonProps> = ({ className, classNameWrapper, text }) => {
  const display = text ? 'inline-flex align-middle' : 'flex flex-1'
  const width = text ? `w-${text}` : 'w-full'

  return (
    <span className={classNames('animate-pulse', display, classNameWrapper)}>
      <span className={classNames('my-[1px] flex h-2.5 rounded-full bg-gray-300 dark:bg-gray-700', className, width)} />
    </span>
  )
}

export default Skeleton
