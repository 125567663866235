import IconDashboard from '@/public/svg/icon_dashboard.svg'
import IconMarkets from '@/public/svg/icon_markets.svg'
import IconManage from '@/public/svg/icon_manage.svg'
import IconStake from '@/public/svg/icon_stake.svg'
// import IconPodl from '@/public/svg/icon_podl.svg'
import IconStats from '@/public/svg/icon_stats.svg'
import IconAirdrops from '@/public/svg/icon_airdrops.svg'
import IconBridge from '@/public/svg/icon_bridge.svg'
import IconCrosschain from '@/public/svg/icon_croschain.svg'
import IconYield from '@/public/svg/icon_yield.svg'

type MainMenuData = {
  title: string
  path: string
  children?: string[]
  more?: boolean
  noTablet?: boolean
  icon?: any
}

export const MainMenuData: MainMenuData[] = [
  {
    title: 'Dashboard',
    path: '/',
    icon: IconDashboard
  },
  {
    title: 'Markets',
    path: '/markets',
    children: ['marketdetail'],
    icon: IconMarkets
  }
  // {
  //   title: 'Yields',
  //   path: '/yields',
  //   icon: IconYield
  // },
  // {
  //   title: 'Manage',
  //   path: '/manage',
  //   noTablet: true,
  //   icon: IconManage
  // },
  // {
  //   title: 'Cross-chain Supply',
  //   path: '/bridge-supply',
  //   noTablet: true,
  //   icon: IconCrosschain
  // },
  // {
  //   title: 'Bridge',
  //   path: '/bridge',
  //   more: true,
  //   icon: IconBridge
  // },
  // {
  //   title: 'Stats',
  //   path: '/stats',
  //   more: true,
  //   icon: IconStats
  // },
  // {
  //   title: 'Stake',
  //   path: '/stake',
  //   more: true,
  //   icon: IconStake
  // },
  // {
  //   title: 'Airdrops',
  //   path: '/airdrops',
  //   more: true,
  //   icon: IconAirdrops
  // }
]
