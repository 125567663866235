import { finOperationsEnum } from '@/config/constants/finOperations'
import { numberFormat } from '@/utils/format'

import HealthFactorNumber from '@/components/Common/HealthFactorNumber'

import ArrowRightIcon from '@/public/svg/arrow-right-modal.svg'

interface ITxOverview {
  method: string
  isCollateralizationUsage: boolean
  supplyAPY: string
  healthFactor: number
  newHealthFactor: number
  debtData: any
}

function TxOverview({
  method,
  isCollateralizationUsage,
  supplyAPY,
  healthFactor,
  newHealthFactor,
  debtData
}: ITxOverview) {
  return (
    <div className="flex flex-col">
      <div className="mb-[10px] flex flex-col">
        <div className="flex">
          <div className="text600 text-sm font-medium">Transaction overview</div>
        </div>
      </div>

      {method === finOperationsEnum.deposit && (
        <div className="bg100 text600 flex flex-col justify-between gap-5 rounded-second px-4 py-3">
          <div className="flex flex-row justify-between">
            <div className="text-base">Supply APY</div>
            <div className="text900 text-base font-medium">{numberFormat(supplyAPY)} %</div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-base">Collateralization Usage</div>
            <div className="text-base">
              {isCollateralizationUsage ? (
                <div className="text-colorSuccess">Yes</div>
              ) : (
                <div className="text-colorError">No</div>
              )}
            </div>
          </div>
        </div>
      )}

      {method === finOperationsEnum.borrow && (
        <div className="bg100 text600 flex flex-col justify-between gap-1 rounded-second px-4 py-3">
          <div className="flex flex-row justify-between">
            <div className="text-base">Health factor</div>
            <div className="flex items-center gap-2 text-base text-colorSuccess">
              <HealthFactorNumber value={healthFactor} />
              <ArrowRightIcon className="stroke1" />
              <HealthFactorNumber value={newHealthFactor} />
            </div>
          </div>
          <div className="flex flex-row justify-end">
            <div className="text-sm font-medium">{`Liquidation at < ${numberFormat(1.0)}`}</div>
          </div>
        </div>
      )}

      {method === finOperationsEnum.withdraw && (
        <div className="bg100 text600 flex flex-col justify-between gap-1 rounded-second px-4 py-3">
          <div className="flex flex-row justify-between">
            <div className="text-base">Health factor</div>
            <div className="flex items-center gap-2 text-base text-colorSuccess">
              <HealthFactorNumber value={healthFactor} />
              <ArrowRightIcon className="stroke1" />
              <HealthFactorNumber value={newHealthFactor} />
            </div>
          </div>
          <div className="flex flex-row justify-end">
            <div className="text-sm font-medium">{`Liquidation at < ${numberFormat(1.0)}`}</div>
          </div>
        </div>
      )}

      {method === finOperationsEnum.repay && (
        <div className="bg100 text600 flex flex-col justify-between gap-1 rounded-second px-4 py-3">
          <div className="flex flex-col justify-between gap-1">
            <div className="flex flex-row justify-between">
              <div className="text-base">Remaining debt</div>
              <div className="text900 flex items-center gap-2 text-base font-medium">
                {numberFormat(debtData.debt, { maxDigits: 8, isSymbolHide: true })}
                <ArrowRightIcon className="stroke1" />{' '}
                {numberFormat(debtData.newDebt, { maxDigits: 8, isSymbolHide: true })}
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <div className="flex items-center gap-2 text-sm">
                ${numberFormat(debtData.debtUsd, { maxDigits: 2, isSymbolHide: true })}
                <ArrowRightIcon className="stroke1" /> $
                {numberFormat(debtData.newDebtUsd, { maxDigits: 2, isSymbolHide: true })}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between gap-1">
            <div className="flex flex-row justify-between">
              <div className="text-base">Health factor</div>
              <div className="flex items-center gap-2 text-base text-colorSuccess">
                <HealthFactorNumber value={healthFactor} />
                <ArrowRightIcon className="stroke1" />
                <HealthFactorNumber value={newHealthFactor} />
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <div className="text-sm font-medium">{'Liquidation at < 1.0'}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default TxOverview
