import { observer } from 'mobx-react'
import Modal from '@/components/Modal/Modal'
import { useAppState } from '@/stores'

import CloseIcon from '@/public/svg/close.svg'
import { TbExternalLink } from 'react-icons/tb'

const BuyLendModal = () => {
  const {
    modalStore: { isShowBuyLend, closeModal }
  } = useAppState()

  return (
    <Modal isShow={isShowBuyLend}>
      <div className="card flex w-full max-w-[440px] flex-col">
        <div className="flex items-center justify-between p-4 pb-2 md:p-6">
          <div className="text900 text-lg font-semibold md:text-2xl">Buy LEND</div>
          <button
            type="button"
            aria-label="close"
            onClick={() => closeModal()}
            className="flex cursor-pointer items-center"
          >
            <CloseIcon className="stroke1 inline-block" />
          </button>
        </div>
        <div className="mb-6 flex flex-col px-4 pb-2 md:px-6">
          <div className="mb-4 flex flex-col">
            <div className="text900 mb-2 text-lg font-semibold">LEND/WMNT :</div>
            <div className="flex flex-col gap-1 pl-4 text-base">
              <a href="https://app.swapsicle.io/swap" target="_blank" className="text600 flex items-center gap-2">
                <div className="">Swapslice</div> <TbExternalLink className="flex items-center" />
              </a>
              <a
                href="https://fusionx.finance/swap?inputCurrency=0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8&outputCurrency=0x25356aeca4210eF7553140edb9b8026089E49396"
                target="_blank"
                className="text600 flex items-center gap-2"
              >
                <div className="">FusionX</div> <TbExternalLink className="flex items-center" />
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text900 mb-2 text-lg font-semibold">LEND/USDC :</div>
            <div className="flex flex-col gap-1 pl-4 text-base">
              <a href="https://izumi.finance/trade/swap" target="_blank" className="text600 flex items-center gap-2">
                <div className="">Izumi Finance</div> <TbExternalLink className="flex items-center" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default observer(BuyLendModal)
