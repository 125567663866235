import { useMemo, ReactElement } from 'react'
import classNames from 'classnames'

import { IFinOperationsStep } from '@/config/constants/finOperations'

import LinkIcon from '@/public/svg/link.svg'
import SpinIcon from '@/public/svg/circle-spin.svg'

const ProgressSteps = ({ current, total, steps }: { current: number; total: number; steps?: IFinOperationsStep[] }) => {
  const stepsRender = useMemo(() => {
    const linesArr: ReactElement<any, any>[] = []

    for (let i = 0; i < total; i++) {
      const row: ReactElement<any, any> = (
        <div
          className={classNames(
            'flex h-1 w-full rounded-[100px]',
            `${i} - ${current}`,
            i < current
              ? i === current - 1 && steps[i - 1]?.isPending
                ? 'bg-colorSuccessLight'
                : 'bg-colorSuccess'
              : 'bg-gray-200 dark:bg-white-200'
          )}
          key={`step-${i}`}
        />
      )
      linesArr.push(row)
    }

    return linesArr
  }, [current, total, steps])

  return <div className="flex flex-row gap-[10px]">{stepsRender}</div>
}
const StatusBar = ({ items }: { items: IFinOperationsStep[] }) => {
  if (items.length === 0) return null

  return (
    <div className="bg100 flex w-full flex-col gap-4 rounded-second px-4 py-3">
      {items.map((item) => (
        <div className="text900 flex flex-row text-xs font-medium md:text-sm" key={item.title}>
          <div className="mr-auto flex">{item.title}</div>
          <div className="flex w-1/3 items-center justify-start md:w-1/4">
            {item.isPending ? (
              <SpinIcon className="mr-2 inline h-3 w-3 animate-spin fill-gray-600 text-gray-200 dark:fill-gray-300 dark:text-gray-600" />
            ) : (
              <span className="mr-2 block h-2 w-2 rounded-full bg-colorSuccess" />
            )}
            status{/* {item.status} */}
          </div>
          <div className="flex w-1/3 justify-end md:w-1/4">
            <a href={item.explorerURL} rel="noopener noreferrer" target="_blank" className="flex items-center gap-2">
              <div className="text900">Explorer</div>
              <LinkIcon className="stroke1" />
            </a>
          </div>
        </div>
      ))}
    </div>
  )
}

interface ProgressProps {
  totalSteps: number
  steps: IFinOperationsStep[]
}

const Progress: React.FC<ProgressProps> = ({ totalSteps, steps }) => (
  <div className="mb-6 flex flex-col gap-4">
    <ProgressSteps total={totalSteps} current={steps.length + 1} steps={steps} />
    <StatusBar items={steps} />
  </div>
)

export default Progress
