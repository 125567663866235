'use client'

import { useState, useEffect } from 'react'

const ClientOnlyRender = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return !hasMounted ? null : children
}

export default ClientOnlyRender
